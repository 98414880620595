<template>
  <el-form class="up-add-message" :model="form" :rules="rules" ref="form">
    <h2 class="up-add-message__title">Добавление нового сообщения</h2>
    <el-form-item label="Ключ" prop="key">
      <el-input v-model="form.key" placeholder="Введите ключ" />
    </el-form-item>
    <el-form-item label="Сообщение" prop="message">
      <el-input v-model="form.message" placeholder="Введите сообщение" />
    </el-form-item>
    <el-button type="success" @click="saveHandler">Сохранить</el-button>
  </el-form>
</template>

<script>
import validatableFormMixin from "@/mixins/validatableForm.mixin";

const requiredValidation = [
  { required: true, message: "Необходимо заполнить", trigger: "blur" },
];

export default {
  name: "AddNewMessage",
  mixins: [validatableFormMixin],
  data: () => ({
    form: {
      key: "",
      message: "",
    },
    rules: {
      key: requiredValidation,
      message: requiredValidation,
    },
  }),
  methods: {
    async saveHandler() {
      if (!(await this.isValid())) return;

      this.$emit("save", this.form);

      this.form = {
        key: "",
        message: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/settings/add-new-message.scss";
</style>