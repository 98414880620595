<template>
  <el-form class="up-form">
    <el-form-item label="Отправитель">
      <el-input v-model="smsSettings.sender" />
    </el-form-item>
    <el-form-item label="Токен">
      <el-input v-model="smsSettings.token" />
    </el-form-item>
    <el-button class="up-form__btn-save" type="success" @click="saveHandler">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

export default {
  name: "SmsSettings",
  mixins: [showErrorMessageMixin],
  props: {
    value: null,
  },
  computed: {
    smsSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async saveHandler() {
      try {
        await this.$axios.put("/admin/settings/sms", this.smsSettings);

        this.$message({
          message: "Поздравляем! Данные настроек смс-шлюза обновлены",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/settings/form-common-styles.scss";
</style>