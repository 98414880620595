<template>
  <div class="up-messages-settings">
    <AppBlockAsTable
      v-if="messagesSettings.length"
      class="up-messages-settings__table"
    >
      <template slot="header">
        <p class="up-messages-settings__key">Ключ</p>
        <p class="up-messages-settings__value">Значение</p>
        <p class="up-messages-settings__methods">Методы</p>
      </template>
      <div
        v-for="(m, key) in messagesSettings"
        :key="key"
        class="up-table__item"
      >
        <p class="up-messages-settings__key">
          <span class="is-pk-hidden">Ключ:</span>{{ key }}
        </p>
        <p class="up-messages-settings__value">
          <span class="is-pk-hidden">Значение:</span>{{ m }}
        </p>
        <p class="up-messages-settings__methods">
          <span class="is-pk-hidden">Методы:</span>
          <i
            class="el-icon-edit"
            role="button"
            aria-label="Редактировать"
            @click="openEditModal(key, m)"
          />
          <i
            class="el-icon-delete"
            role="button"
            aria-label="Удалить"
            @click="openRemoveModal(key, m)"
          />
        </p>
      </div>
    </AppBlockAsTable>
    <AddNewMessage @save="addNewMessage" />
    <AppSimpleModal
      :visible.sync="editModalVisible"
      title="Редактирование записи"
      action-text="Редактировать"
      @action="editHandler"
      @close="editModalVisible = false"
    >
      Вы собираетесь отредактировать сообщение с ключом: {{ messageData.key }}.
      Запишите новое сообщение.
      <template slot="body">
        <el-input
          class="up-messages-settings__new-message-input"
          v-model="newMessageValue"
        />
      </template>
    </AppSimpleModal>
    <AppSimpleModal
      :visible.sync="removeModalVisible"
      title="Удаление записи"
      @action="removeHandler"
      @close="removeModalVisible = false"
    >
      Вы собираетесь удалить запись: {{ messageData.key }} -
      {{ messageData.message }} .Выберите нужно действие
    </AppSimpleModal>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSimpleModal from "@/components/AppSimpleModal";
import AddNewMessage from "@/components/admin/settings/AddNewMessage";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

export default {
  name: "MessagesSettings",
  mixins: [showErrorMessageMixin],
  components: {
    AppBlockAsTable,
    AppSimpleModal,
    AddNewMessage,
  },
  props: {
    value: null,
  },
  data: () => ({
    editModalVisible: false,
    removeModalVisible: false,
    messageData: {},
    newMessageValue: "",
  }),
  computed: {
    messagesSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async save() {
      try {
        const { data: res } = await this.$axios.put(
          "/admin/settings/messages",
          this.messagesSettings
        );

        this.messagesSettings = res.messages;

        this.$message({
          message: "Поздравляем! Данные сообщений обновлены",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    addNewMessage({ key, message }) {
      this.messagesSettings[key] = message;

      this.save();
    },
    openEditModal(key, message) {
      this.editModalVisible = true;
      this.newMessageValue = message;
      this.messageData.key = key;
    },
    openRemoveModal(key, message) {
      this.removeModalVisible = true;
      this.messageData.key = key;
      this.messageData.message = message;
    },
    async removeHandler() {
      try {
        const { data: res } = await this.$axios.delete(
          `/admin/settings/message/${this.messageData.key}`
        );

        this.messagesSettings = res.messages;
        this.removeModalVisible = false;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    editHandler() {
      this.messagesSettings[this.messageData.key] = this.newMessageValue;

      this.save();

      this.editModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/settings/messages-settings.scss";
</style>