<template>
  <el-form class="up-form">
    <el-form-item label="Логин">
      <el-input v-model="mailSettings.login" />
    </el-form-item>
    <el-form-item label="Порт">
      <el-input v-model="mailSettings.port" />
    </el-form-item>
    <el-form-item label="Сервер">
      <el-input v-model="mailSettings.server" />
    </el-form-item>
    <el-form-item label="Пароль">
      <el-input
        v-model="mailSettings.password"
        placeholder="* * * * * * * * * *"
      />
    </el-form-item>
    <el-button class="up-form__btn-save" type="success" @click="saveHandler">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

export default {
  name: "MailSettings",
  mixins: [showErrorMessageMixin],
  props: {
    value: null,
  },
  computed: {
    mailSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async saveHandler() {
      try {
        await this.$axios.put("/admin/settings/mail", this.mailSettings);

        this.$message({
          message: "Поздравляем! Данные настроек mail обновлены",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/settings/form-common-styles.scss";
</style>