<template>
  <div class="pr-documents">
    <div class="pr-documents__item">
      <a
        v-if="documents.privacy.hasFile"
        class="pr-documents__link"
        :href="documents.privacy.downloadLink"
        download
      >
        <i class="pr-documents__icon el-icon-document" />
        {{ documents.privacy.name }}
      </a>
      <el-upload
        action="#"
        :auto-upload="false"
        :on-change="loadPrivacyHandler"
        :show-file-list="false"
      >
        <el-button type="primary">
          Загрузить {{ documents.privacy.name }}
        </el-button>
      </el-upload>
    </div>
    <div class="pr-documents__item">
      <a
        v-if="documents.user_agreement.hasFile"
        class="pr-documents__link"
        :href="documents.user_agreement.downloadLink"
        download
      >
        <i class="pr-documents__icon el-icon-document" />
        {{ documents.user_agreement.name }}
      </a>
      <el-upload
        action="#"
        :auto-upload="false"
        :on-change="loadUserAgreementHandler"
        :show-file-list="false"
      >
        <el-button type="primary">
          Загрузить {{ documents.user_agreement.name }}
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapGetters } from "vuex";

export default {
  name: "Documents",
  mixins: [showErrorMessageMixin],
  computed: {
    ...mapGetters({ documents: "getDocuments" }),
  },
  methods: {
    async updateFile(fileRaw, key = "privacy") {
      const fd = new FormData();
      fd.append("file", fileRaw);

      try {
        await this.$axios.post(`/admin/attachment/${key}`, fd);

        this.$message({
          message: "Поздравляем! Файл успешно обновлен.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    loadPrivacyHandler(file) {
      this.updateFile(file.raw);
    },
    loadUserAgreementHandler(file) {
      this.updateFile(file.raw, "user_agreement");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/settings/documents.scss";
</style>