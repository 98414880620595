<template>
  <div class="up-settings">
    <h1 class="up-main-title">{{ pageTitle }}</h1>
    <el-tabs v-model="activeComponent">
      <el-tab-pane label="Настройки email" name="MailSettings" />
      <el-tab-pane label="Настройки смс-шлюза" name="SmsSettings" />
      <el-tab-pane label="Настройки сообщений" name="MessagesSettings" />
      <el-tab-pane label="Документы" name="Documents" />
    </el-tabs>
    <MailSettings
      v-if="activeComponent === 'MailSettings'"
      v-model="mailSettings"
    />
    <SmsSettings
      v-else-if="activeComponent === 'SmsSettings'"
      v-model="smsSettings"
    />
    <MessagesSettings
      v-else-if="activeComponent === 'MessagesSettings'"
      v-model="messagesSettings"
    />
    <Documents v-else />
  </div>
</template>

<script>
import MailSettings from "@/components/admin/settings/MailSettings";
import SmsSettings from "@/components/admin/settings/SmsSettings";
import MessagesSettings from "@/components/admin/settings/MessagesSettings";
import Documents from "@/components/admin/settings/Documents";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "Settings",
  mixins: [showErrorMessageMixin],
  components: { MailSettings, SmsSettings, MessagesSettings, Documents },
  data: () => ({
    mailSettings: {},
    smsSettings: {},
    messagesSettings: {},
    activeComponent: "MailSettings",
  }),
  computed: {
    pageTitle() {
      switch (this.activeComponent) {
        case "MailSettings":
          return "Настройки Email";
        case "SmsSettings":
          return "Настройки смс-шлюза";
        case "Documents":
          return "Документы";
        default:
          return "Настройки сообщений";
      }
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchSettings() {
      try {
        const { data: settings } = await this.$axios.get("/admin/settings");

        this.mailSettings = settings.mail || {};
        this.smsSettings = settings.sms || {};
        this.messagesSettings = settings.messages || {};
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Страница настроек");

    this.fetchSettings();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/settings.scss";
</style>